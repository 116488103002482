<template>
  <div class="min-h-screen py-12 bg-hp-gray dark:bg-gray-900 dark:text-white">
    <div class="max-w-5xl px-4 mx-auto">
      <!-- Navigation -->
      <div class="flex justify-between items-center mb-40">
        <router-link to="/">
          <img src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 2.1rem;" alt="Homeplan logo">
        </router-link>
        
       <router-link to="/" class="py-1.5 px-2.5 bg-blue-900 text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none rounded-lg">
          Back to home
        </router-link>
      </div>

      <!-- Page heading -->
      <div class="text-6xl text-center font-bold mb-28">Data Retention Policy</div>

      <div class="pb-24">
        <p>Homeplan.com, PBC seeks to ensure that it retains only data necessary to effectively conduct its program activities and work in fulfilment of its mission.</p>
        <p>
          The need to retain data varies widely with the type of data and the purpose for which it was collected. Homeplan.com, PBC strives to ensure that data is only retained for the period necessary to fulfil the purpose for which it was
          collected and is fully deleted when no longer required. This policy sets forth Homeplan.com, PBC’s guidelines on data retention and is to be consistently applied throughout the organization.
        </p>
        <p>
          <br /><br />
          <strong>Scope</strong>
        </p>
        <p>
          This policy covers all data collected by Homeplan.com, PBC and stored on Homeplan.com, PBC owned or leased systems and media, regardless of location. It applies to data collected and held electronically (including text, financial
          data, photographs, video and audio recordings). The need to retain certain information may be mandated by federal or local law, federal regulations and legitimate business purposes, as well as the EU General Data Protection
          Regulation (GDPR), the California Consumer Privacy Act (CCPA), and the Payment Card Industry Data Security Standard (PCI DSS).
        </p>
        <p>
          <br /><br />
          <strong>Purpose</strong>
        </p>
        <p>
          The purpose of this policy is to provide a policy that ensures data is accurately protected and maintained and that data containing personal information is destroyed at the appropriate time. This policy helps ensure individuals’
          data protection rights. All processing of personal data, per the Company’s policy, must be fair, proportionate, and compatible for the purpose for which it was collected. All data is only retained so long as necessary and is
          destroyed when no longer necessary. Security is also important, and personal data is protected by appropriate security measures by the Company.
        </p>
        <p>
          <br /><br />
          <strong>Reasons for Data Retention</strong>
        </p>
        <p>Homeplan.com, PBC retains only that data that is necessary to effectively conduct its program activities, fulfill its mission and comply with applicable laws and regulations.</p>
        <p>Reasons for data retention include:</p>
        <ul>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">
            Providing an ongoing service to the data subject (e.g. sending a newsletter, publication or ongoing program updates to an individual, ongoing participation in Homeplan.com, PBC’s programs and services, including all home
            management and maintenance services and including but not limited to providing contractor options, insurance options, and third-party contacts).
          </li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">
            Compliance with applicable laws and regulations associated with local laws and regulations and compliance with real estate and insurance laws by Homeplan.com, PBC.
          </li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Compliance with applicable labor, tax and immigration laws</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Other regulatory requirements</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Security incident or other investigation</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Intellectual property preservation</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Litigation</li>
        </ul>
        <p>
          <br /><br />
          <strong>Data Duplication</strong>
        </p>
        <p>
          Homeplan.com, PBC seeks to avoid duplication in data storage whenever possible, though there may be instances in which for programmatic or other business reasons it is necessary for data to be held in more than one place. This
          policy applies to all data in Homeplan.com, PBC’s possession, including duplicate copies of data.
        </p>
        <p>
          <br /><br />
          <strong>Retention Requirements</strong>
        </p>
        <p>Homeplan.com, PBC has set the following guidelines for retaining all personal data as defined in the Company’s data Privacy Policy available on joinhomeplan.com.</p>
        <ul style="list-style-type: circle">
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Website visitor data will be retained as long as necessary to provide the service requested/initiated through the com, PBC website.</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">
            Contributor data will be retained for the year in which the individual has contributed and then for twenty-four (24) months after the date of the last contribution. Financial information will not be retained longer than is
            necessary to process a single
          </li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">
            Event participant data will be retained for the period of the event, including any follow up activities, such as the distribution of reports, plus a period of twenty-four (24) months;
          </li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Personal data of subgrantees, subcontractors and vendors will be kept for the duration of the contract or agreement.</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Employee data will be held for the duration of employment and then twenty-four (24) months after the last day of</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">
            Data associated with employee wages, leave and pension shall be held for the period of employment plus twenty-four (24) months, with the exception of pension eligibility and retirement beneficiary data which shall be kept for
            twenty-four (24) months.
          </li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">
            Recruitment data, including interview notes of unsuccessful applicants, will be held for twenty-four (24) months after the closing of the position recruitment
          </li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">
            Consultant (both paid and pro bono) data will be held for the duration of the consulting contract plus twenty-four (24) months after the end of the
          </li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Board member data will be held for the duration of service on the Board plus for twenty-four (24) months after the end of the member’s</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">Data associated with tax payments (including payroll, corporate and VAT) will be held for twenty-four (24) months.</li>
          <li style="list-style: disc outside none; display: list-item; margin-left: 1em">
            Operational data related to services, home management and maintenance will be held for the period required by the Homeplan.com, PBC donor, but not more than twenty-four (24) months.
          </li>
        </ul>
        <p>
          <br /><br />
          <strong>Data Destruction</strong>
        </p>
        <p>
          Data destruction ensures that Homeplan.com, PBC manages the data it controls and processes it in an efficient and responsible manner. When the retention period for the data as outlined above expires, Homeplan.com, PBC will
          actively destroy the data covered by this policy. If an individual believes that there exists a legitimate business reason why certain data should not be destroyed at the end of a retention period, he or she should identify this
          data to Homeplan.com, PBC and provide information as to why the data should not be destroyed. Any exceptions to this data retention policy must be approved by Homeplan.com, PBC’s data protection offer in consultation with legal
          counsel. In rare circumstances, a litigation hold may be issued by legal counsel prohibiting the destruction of certain documents and data. A litigation hold remains in effect until released by legal counsel and prohibits the
          destruction of data subject to the hold.
        </p>
      </div>
      

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";

export default {
  components: {
    Footer
  },
}
</script>